var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"page-header"},[_c('Breadcrumbs',{attrs:{"main":"Digital","title":"Add Products"}})],1),_c('div',{staticClass:"row product-adding"},[_c('div',{staticClass:"col-xl-6"},[_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"digital-add needs-validation"},[_c('div',{staticClass:"form-group"},[_vm._m(1),_c('ValidationProvider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.title),expression:"form.title"}],staticClass:"form-control",attrs:{"id":"validationCustom01","type":"text","required":""},domProps:{"value":(_vm.form.title)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "title", $event.target.value)}}}),_c('span',{staticClass:"block text-danger text-xs absolute bottom-0 left-0"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('label',{staticClass:"col-form-label pt-0"},[_vm._v(" Product Upload")]),_c('vue-dropzone',{staticClass:"dropzone digits",attrs:{"id":"singledropzone","options":_vm.singledropzoneOptions}})],1)])])]),_c('div',{staticClass:"col-xl-6"},[_c('div',{staticClass:"card"},[_vm._m(7),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"digital-add needs-validation"},[_c('div',{staticClass:"md-12"},[_c('vue-editor',{model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}})],1)])])]),_vm._m(8)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h5',[_vm._v("General")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"col-form-label pt-0",attrs:{"for":"validationCustom01"}},[_c('span',[_vm._v("*")]),_vm._v(" Title")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-form-label pt-0",attrs:{"for":"validationCustomtitle"}},[_c('span',[_vm._v("*")]),_vm._v(" SKU")]),_c('input',{staticClass:"form-control",attrs:{"id":"validationCustomtitle","type":"text","required":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-form-label"},[_c('span',[_vm._v("*")]),_vm._v(" Categories")]),_c('select',{staticClass:"custom-select",attrs:{"required":""}},[_c('option',{attrs:{"value":""}},[_vm._v("--Select--")]),_c('option',{attrs:{"value":"1"}},[_vm._v("eBooks")]),_c('option',{attrs:{"value":"2"}},[_vm._v("Graphic Design")]),_c('option',{attrs:{"value":"3"}},[_vm._v("3D Impact")]),_c('option',{attrs:{"value":"4"}},[_vm._v("Application")]),_c('option',{attrs:{"value":"5"}},[_vm._v("Websites")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-form-label"},[_vm._v("Sort Summary")]),_c('textarea',{attrs:{"rows":"5","cols":"12"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-form-label",attrs:{"for":"validationCustom02"}},[_c('span',[_vm._v("*")]),_vm._v(" Product Price")]),_c('input',{staticClass:"form-control",attrs:{"id":"validationCustom02","type":"text","required":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-form-label"},[_c('span',[_vm._v("*")]),_vm._v(" Status")]),_c('div',{staticClass:"m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated"},[_c('label',{staticClass:"d-block",attrs:{"for":"edo-ani"}},[_c('input',{staticClass:"radio_animated",attrs:{"id":"edo-ani","type":"radio","name":"rdo-ani"}}),_vm._v(" Enable ")]),_c('label',{staticClass:"d-block",attrs:{"for":"edo-ani1"}},[_c('input',{staticClass:"radio_animated",attrs:{"id":"edo-ani1","type":"radio","name":"rdo-ani"}}),_vm._v(" Disable ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h5',[_vm._v("Add Description")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h5',[_vm._v("Meta Data")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"digital-add needs-validation"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-form-label pt-0",attrs:{"for":"validationCustom05"}},[_vm._v(" Meta Title")]),_c('input',{staticClass:"form-control",attrs:{"id":"validationCustom05","type":"text","required":""}})]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-form-label"},[_vm._v("Meta Description")]),_c('textarea',{attrs:{"rows":"4","cols":"12"}})]),_c('div',{staticClass:"form-group mb-0"},[_c('div',{staticClass:"product-buttons text-center"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"}},[_vm._v(" Add ")]),_c('button',{staticClass:"btn btn-light ml-1",attrs:{"type":"button"}},[_vm._v(" Discard ")])])])])])])
}]

export { render, staticRenderFns }