import axios from "axios";
import config from '../../../config.json';
const ax = axios.create({
    baseURL: config.baseUrl,
});
const url = 'action.php';

const state = {
    testList: []
}
const getters = {
    getCateg(state) {
        console.log(state);
        return state.testList;
    }
}
const actions = {
    getCateg(context) {
        ax.get(url)
            .then(response => {
                console.log(response.data);
                context.commit('getCateg', response);
            })
            .catch(error => {
                console.log('error', error);
            })
    }
}
const mutations = {
    getCateg(state, payload) {
        state.testList = payload.data;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}